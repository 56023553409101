#quizResultsAnswersContainer {
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  min-height: 100%;
}

#quizResultsAnswersContainer h2 {
  font-family: noto-bold;
  font-size: 13px;
  margin-top: 24px;
  color: var(--primary-accent);
}

#quizResultsAnswersContainer h1 {
  font-family: noto-bold;
  font-size: 15px;
  margin-top: 4px;
  margin-bottom: 15px;
  color: var(--primary-accent);
}

#quizResultsAnswersContainer h3 {
  font-family: orbi-med;
  font-size: 20px;
  color: var(--results-h3);
}

#quizResultsAnswersContainer #resultsTitle {
  margin-top: 24px;
}

#quizResultsAnswersContainer #answersTitle {
  margin-top: 40px;
}

#quizResultsAnswersContainer #score {
  margin-top: 8px;
  font-family: noto-reg;
  font-size: 20px;
  color: var(--primary-accent);
}

#quizResultsAnswersContainer #totScore {
  font-family: noto-bold;
  font-size: 64px;
}
