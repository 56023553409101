.modalContent ion-list {
  padding-top: 0;
  padding-bottom: 0;
}

#modalClose {
  --color: white;
  --font-family: noto-bold;
  font-weight: bold;
  font-size: 17px;
  text-transform: capitalize;
  /* margin-right: 5px; */
}

.contentsModal {
  /* bottom: 0; */
  /* min-height: initial; */
  /* top: initial; */
}

.contentsModal::part(content) {
  --width: 100%;
  --height: 90vh;
  margin-bottom: -10vh;
  --border-radius: 10px 10px 0 0;
  background-color: var(--content-bg);
}

@media only screen and (min-width: 600px) {
  .contentsModal::part(content) {
    --width: 75%;
  }
}

.modalContent {
  /* padding-left: 10px; */
  /* --paddingRight: 10px; */
  padding-left: 14px;
  padding-right: 14px;
}
