#latestBulletinBubble {
  margin-top: 12px;
  border: solid 1px var(--lb-bubble-border);
  border-radius: 10px 0 10px 0;
  padding: 16px;
  background-color: var(--lb-bubble-bg);
  cursor: pointer;
}

#latestBulletinBubble:hover {
  background-color: var(--lb-bubble-bg-hover);
}

#latestBulletinBubble:active {
  background-color: var(--lb-bubble-bg-active);
}

#latestBulletinBubble h3:nth-child(1) {
  font-family: orbi-bold;
  font-size: 13px;
  color: var(--lb-bubble-date);
  user-select: none;
}

#latestBulletinBubble h3:nth-child(2) {
  margin-top: 4px;
  font-size: 20px;
  font-family: noto-bold;
  color: var(--lb-bubble-title);
  user-select: none;
}
