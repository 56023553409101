#overviewSummaryBox,
#overviewQuizBox,
#overviewCaseStudyBox {
  padding-top: 24px;
  padding-bottom: 32px;
}

#overviewSummaryBox h2 {
  font-family: orbi-bold;
  font-size: 15px;
  color: var(--quiz-overview-mod-number);
}

#overviewSummaryBox h1 {
  font-family: noto-bold;
  font-size: 22px;
  color: var(--quiz-overview-box-title);
  margin-top: 4px;
}

#overviewSummaryBox h3 {
  margin-top: 10px;
  /* color: red !important; */
  font-family: noto-bold;
  font-size: 15px;
}

#overviewSummaryBox p {
  font-family: noto-reg;
  font-size: 17px;
  margin-top: 16px;
  color: var(--overview-description);
}

#learnMorelink {
  font-family: noto-bold;
  font-size: 15px;
  margin-top: 8px;
  color: var(--overview-learn-more-link);
  cursor: pointer;
}

#boxItemDivider {
  /* width: 100%; */
  height: 1px;
  background-color: var(--box-item-divider);
}

#overviewQuizBox h1,
#overviewCaseStudyBox h1 {
  font-family: noto-bold;
  font-size: 20px;
  color: var(--quiz-overview-box-title);
}

#overviewQuizBox h2,
#overviewCaseStudyBox h2 {
  margin-top: 8px;
  font-family: noto-reg;
  font-size: 15px;
  color: var(--quiz-overview-box-title);
}

#overviewQuizBox h3,
#overviewCaseStudyBox h3 {
  margin-top: 8px;
  font-family: noto-reg;
  font-size: 17px;
  color: var(--quiz-overview-box-best-attempt-score);
}

#overviewQuizBox div,
#overviewCaseStudyBox div {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.bestAttemptScore {
  font-size: 64px;
  font-family: noto-bold;
}
