#swiper-controls-container {
  /* height: 100px; */
  background-color: var(--tab-highlight-default);
}

#swiper-controls {
  height: var(--swiper-controls-height);
  width: 100%;
  background-color: var(--tab-highlight-default);
  z-index: 1;
  border-top: 1px solid var(--tab-bar-border);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: env(safe-area-inset-bottom); */
}

#swiper-controls ion-button {
  /* width: 135px; */
  /* border: solid 1px black; */
}

#swiper-controls ion-button::part(native) {
  padding: 0;
}

#swiper-controls ion-button.finishButton::part(native) {
  padding: 15px;
}

.swiperControlPrev,
.swiperControlNext,
.finishButton {
  color: var(--swiper-controls-text);
  font-family: noto-reg;
  --background: transparent;
}

.finishButton {
  --background: none;
  font-family: noto-bold;
}

#swiper-controls ion-button ion-icon {
  /* border: dotted 1px red; */
  width: 44px;
  height: 44px;
  filter: var(--caret-icon);
}

.swiperControlPrev ion-icon {
  margin-right: -5px;
}

.middleBtn {
  width: auto !important;
  font-family: noto-bold;
  color: var(--swiper-controls-text);
}

.swiperControlNext ion-icon {
  margin-left: -5px;
}
