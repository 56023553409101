#continueReadingBubble {
  border: solid 1px var(--cr-bubble-border);
  border-radius: 10px 0 10px 0;
  padding: 16px 16px 16px 16px;
  background-color: var(--cr-bubble-bg);
  cursor: pointer;
}

/* #continueReadingBubble:hover {
  background-color: var(--cr-bubble-bg-hover);
} */

#continueReadingBubble:active {
  /* background-color: var(--cr-bubble-bg-active); */
  background-color: var(--cr-bubble-bg-hover);
}

@media (hover: hover) {
  #continueReadingBubble:hover {
    background-color: var(--cr-bubble-bg-hover);
  }
}

#continueReadingBubble h3:nth-child(1) {
  font-family: orbi-bold;
  font-size: 13px;
  color: var(--cr-bubble-module-num);
  user-select: none;
}

#continueReadingBubble h3:nth-child(2) {
  margin-top: 4px;
  font-size: 20px;
  font-family: noto-bold;
  color: var(--cr-bubble-module-name);
  user-select: none;
}

#chapterLineContainer {
  margin-top: 22px;
  display: flex;
  align-items: center;
}

#chapterLineContainer #chapterName {
  font-family: noto-bold;
  font-size: 17px;
  color: var(--cr-bubble-chapter);
  user-select: none;
  flex: 1;
}

#chapterLineContainer #chapterArrow {
  width: 44px;
  height: 44px;
  filter: var(--cr-chapter-icon);
}
