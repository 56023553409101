#chapterListContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#chapterListContainer h2 {
  font-family: orbi-bold;
  color: var(--module-list-item-number);
  margin-bottom: 4px;
  font-size: 13px;
}

#chapterListContainer h1 {
  font-family: noto-bold;
  color: var(--module-list-item-name);
  margin-bottom: 8px;
  font-size: 20px;
}

#moduleButtonsContainer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  font-family: noto-bold;
  padding-top: 20px;
}

#moduleButtonsContainer ion-label {
  color: var(--module-buttons-label);
}

#moduleButtonsContainer ion-button:nth-child(2) {
  margin-bottom: 12px;
}

#moduleButtonsContainer ion-button {
  margin-top: 12px;
  width: 100%;
  opacity: 1;
  --background: var(--reg-button);
  --border-radius: 2px;
  --background-hover: var(--reg-button-hover);
  --background-activated: var(--reg-button-activated);
}

@media only screen and (min-width: 600px) {
  #moduleButtonsContainer ion-button {
    width: 50%;
  }
}

#moduleButtonsContainer ion-button ion-icon {
  margin-right: 3px;
  margin-left: -8px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(104%) contrast(105%);
}
