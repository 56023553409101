#bookmarksListContainer {
  margin-top: 16px;
}

.emptyBookmarksContainer {
  font-family: noto-reg;
  text-align: center;
  font-size: 15px;
  color: var(--dash-empty-section);
}
