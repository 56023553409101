.quizInfoModal {
}

.quizInfoModal::part(content) {
  --width: 100%;
  --height: 90vh;
  margin-bottom: -10vh;
  --border-radius: 10px 10px 0 0;
  background-color: var(--content-bg);
}

@media only screen and (min-width: 600px) {
  .quizInfoModal::part(content) {
    --width: 75%;
  }
}

.quizInfoModal h1 {
  margin-top: 24px;
  font-family: noto-bold;
  font-size: 22px;
  color: var(--secondary-accent);
}

.quizInfoModal p {
  margin-top: 24px;
  /* margin-left: 15px; */
  /* margin-right: 15px; */
  font-family: noto-reg;
  font-size: 17px;
  color: var(--primary-accent);
}

#quizInfoModalToolbar {
  padding-top: 0;
}
