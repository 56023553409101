#innerContainer ion-label {
  font-family: noto-bold;
  color: black;
  /* --width: 100%; */
  margin-top: 12px;
}

#innerContainer p {
  font-family: noto-reg;
  color: black;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

#innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}

.markAsFinishedModal::part(content) {
  --border-radius: 8px;
  --width: 75%;
  --background: white;

  min-height: 200px;
  height: auto;
}

@media only screen and (min-width: 600px) {
  .markAsFinishedModal::part(content) {
    --width: 30%;
  }
}

#buttonsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#buttonsContainer ion-button {
  margin: 0;
  --border-radius: 0;
  --border: none;
  --color: var(--modal-button);
}

.modalDivider {
  height: 1px;
  background-color: var(--modal-divider);
}

.maf {
  font-family: noto-bold;
}

#cancel {
  font-family: noto-reg;
}
