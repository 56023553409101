#attemptAnswerContainer {
  padding-top: 24px;
  padding-bottom: 24px;
}

#attemptAnswerContainer #answerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

#attemptAnswerContainer #answerText {
  flex: 1;
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: noto-reg;
  font-size: 17px;
  color: var(--primary-accent);
}

#attemptAnswerContainer #questionText {
  margin-top: 4px;
  font-family: noto-reg;
  font-size: 17px;
  color: var(--primary-accent);
}

#attemptAnswerContainer #outerAnswerContainer {
  margin-top: 12px;
  padding-left: 12px;
}

#attemptAnswerContainer #questionNumber {
  font-family: noto-bold;
  font-size: 13px;
  /* color: red; */
  color: var(--secondary-accent);
}

#attemptAnswerContainer #answerTitle {
  font-family: noto-bold;
  font-size: 13px;
  color: var(--secondary-accent);
}

#attemptAnswerContainer #attemptAnswerIcon.filterTick {
  filter: var(--attempt-answer-icon-correct);
  /* width: 100px; */
  /* height: 100px; */
}

#attemptAnswerContainer #attemptAnswerIcon.filterCross {
  filter: var(--attempt-answer-icon-incorrect);
}
