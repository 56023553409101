.counterTitle {
  font-family: noto-bold;
}

/* ************** STYLING FOR AIRTABLE CLASSES ************** */

.Subhead-4 {
  /* font-weight: bold; */
  font-family: noto-bold !important;
}

b {
  font-family: noto-bold !important;
}

/* dot bullet point */
.Text-tab-1a {
  /* padding-left: 15px; */
}

/* dash bullet point */
.Text-tab-2a {
  /* padding-left: 30px; */
}

table {
  border: 1px solid var(--primary-accent);
  border-collapse: collapse;
}

.tableContainer {
  overflow-x: scroll;
}

tr {
}

td {
  border: 1px solid var(--primary-accent);
  padding: 4px;
}

th {
  background: var(--th-bg);
}

.bullet-point-dash {
  margin-left: 12px;
}

.thbg {
  background: var(--th-bg);
}

/* ************************************************************ */

#chapterContentSwiper {
  height: calc(100% - var(--swiper-controls-height) - env(safe-area-inset-bottom));
}

#chapterContentSwiper h3 {
  margin-top: 24px;
  font-family: noto-bold;
  font-size: 13px;
  color: var(--chapter-content-text);
}

#chapterContentSwiper h2 {
  font-family: noto-bold;
  font-size: 15px;
  color: var(--chapter-content-text);
  margin-top: 4px;
}

#chapterContentSwiper h1 {
  font-family: noto-bold;
  /* font-size: 20px; */
  color: var(--chapter-content-text);
  margin-top: 24px;
}

.x {
  padding-top: 20px !important;
  /* margin-top: 10px; */
}

#chapterContentSwiper #divider {
  margin-top: 24px;
  /* margin-bottom: 24px; */
  min-height: 1px;
  background-color: var(--chapter-content-divider);
}

#chapterContentSwiper .htmlContainer {
  color: var(--chapter-content-text);
  font-family: noto-reg;
  /* max-width: 100%; */
  overflow-x: hidden;
}

#chapterContentSwiper .swiper-slide {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

#chapterContentButtonsContainer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-top: 20px;
  margin-bottom: 30px;
  align-items: center;
  font-family: noto-bold;
  /* border: dotted 1px black; */
}

#chapterContentButtonsContainer ion-label {
  color: var(--module-buttons-label);
}

#chapterContentButtonsContainer ion-button {
  margin-top: 12px;
  width: 100%;
  opacity: 1;
  --border-radius: 2px;
  --background: var(--reg-button);
  --background-activated: var(--reg-button-activated);
}

#chapterContentButtonsContainer ion-button ion-icon {
  margin-right: 3px;
  margin-left: -8px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(104%) contrast(105%);
}

@media only screen and (min-width: 600px) {
  #chapterContentButtonsContainer ion-button {
    width: 50%;
  }
}

.htmlContainer p {
  margin-top: 20px;
}

.htmlContainer p img {
  display: block;
  margin: 0 auto;
}

#searchContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#searchContainer ion-button:nth-child(1) {
  /* color: red; */
  height: var(--search-container-height);
  color: white;
  font-family: noto-bold;
  margin: 4px 0;
}

#searchContainer ion-item {
  width: 100%;
  /* background: blue; */
}

#searchContainer ion-item::part(native) {
  background-color: var(--search-input-bg);
}

#searchContainer ion-item::part(native) {
  border-radius: 8px;
  padding-left: 0;
}

#searchContainer ion-input {
  /* background-color: var(--search-input-bg); */

  border-width: 0;
  --padding-start: 10px;
  height: 39px;
  flex: 1;
  color: var(--search-input-color);
}

#searchContainer ion-icon {
  width: 44px;
  height: 44px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(357deg) brightness(107%) contrast(106%);
}

.arrowBtn::part(native) {
  padding: 0;
  /* margin: 0 !important; */
}

#arrowBtnContainer {
  display: flex;
  flex-direction: row;
}

#arrowBtnContainer ion-button {
  margin: 0;
}

#occurencesContainer {
  color: var(--occurences-container);
  display: flex;
  align-items: center;
  /* padding-right: 10px; */

  background-color: var(--search-input-bg);
  border-radius: 0 8px 8px 0;
  /* margin-right: 8px; */
  height: 39px;
}

.highlightText {
  background-color: blue;
}

mark {
  background-color: var(--highlight-default);
}

.activeHighlight {
  background-color: var(--highlight-active);
}
