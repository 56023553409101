#menuItemContainer {
  cursor: pointer;
  --padding-end: 20px;
  --background-hover: none;
  --background-activated: none;
}

#menuItemContainer:hover {
  --background-color: none;
}

#menuItemText {
  color: var(--menu-item-label);
  font-family: noto-bold;
  font-size: 17px;
  margin-top: 14px;
}

#menuItemSubText {
  color: var(--menu-item-subtext);
  font-family: noto-reg;
  font-size: 13px;
  margin-top: 2px;
}

.dashSubMenu ion-button {
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  width: 100%;
  /* border: solid 1px black; */
  --border-radius: 2px;
  --background: none;
  --background-activated: none;
  --background-hover: none;
  font-family: noto-bold;
}

#menuItemContainer a {
  text-decoration: none;
}
