#quizQuestionsSwiper {
  height: calc(100% - var(--swiper-controls-height));
}

#quizQuestionsSwiper p {
  font-size: 22px;
  margin-top: 24px;
  font-family: noto-reg;
  color: var(--question-text);
  margin-bottom: 24px;
}

#quizQuestionsSwiper .swiper-slide {
  padding-left: 20px;
  padding-right: 20px;
  /* border: solid 1px green; */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

#quizQuestionsSwiper #divider {
  margin-top: 15px;
  min-height: 1px;
  background-color: var(--chapter-content-divider);
}

#quizQuestionsSwiper h1 {
  margin-top: 4px;
  font-family: noto-bold;
  color: var(--question-mod-name);
  font-size: 15px;
}

#quizQuestionsSwiper h2 {
  font-size: 13px;
  margin-top: 16px;
  font-family: noto-bold;
  color: var(--question-mod-number);
}

.caseStudyInput {
  background-color: var(--answer-box-default-bg);
  border-radius: 4px;
  --padding-start: 12px;
  --padding-end: 12px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  margin-bottom: 20px;
  color: var(--primary-accent);
}

#caseStudyCorrectAnswer {
  margin-bottom: 24px;
  color: var(--answer-box-green-text) !important;
  background-color: var(--answer-box-green-bg) !important;
  padding: 12px;
  font-family: noto-reg;
  font-size: 17px;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px !important;
  border-color: var(--answer-box-selected-border);
}

.caseStudyLabel {
  margin-bottom: 8px;
  font-family: noto-bold;
  font-size: 13px;
  color: #6f757d;
}

ion-range {
  --bar-background: var(--primary-accent);
  --bar-background-active: var(--primary-accent);
}

ion-range::part(tick) {
  background: var(--primary-accent);
}

ion-range ion-label {
  color: var(--primary-accent);
}
