#registerContent {
}

#registerContent .contentWrapper {
  background: white !important;
}

#registerContentContainer {
  background: white !important;

  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
