#moduleListItem {
  --inner-padding-top: 20px;
  --inner-padding-bottom: 20px;
  --inner-padding-start: 20px;
  --inner-padding-end: 20px;
}

#moduleListItem::hover {
  --background: blue;
}

#moduleListItem::part(detail-icon) {
  height: 100%;
}

#moduleListItem::part(native) {
  padding-left: 0;
  padding-right: 0;
}

#moduleListItem h2 {
  color: var(--module-list-item-number);
  font-family: orbi-bold;
  font-size: 13px;
}

#moduleListItem h1 {
  color: var(--module-list-item-name);
  font-family: noto-bold;
  font-size: 20px;
  margin-top: 4px;
}

#moduleListItem h3 {
  margin-top: 8px;
  font-family: noto-bold;
  font-size: 15px;
}

#moduleListItem h3.status-not-started {
  color: var(--status-not-started);
}

#moduleListItem h3.status-in-progress {
  color: var(--status-in-progress);
}

#moduleListItem h3.status-finished {
  color: var(--status-finished);
}
