#chapterListItem ion-label {
  --color: var(--chapter-list-item);
  font-family: noto-reg;
  font-size: 17px;
}

#chapterListItem #bookIcon {
  filter: var(--chapter-list-book-icon);
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-left: -6px;
}

#chapterListItem #tickIcon {
  filter: var(--chapter-list-tick-icon);
}

#chapterListItem::part(native) {
  padding-left: 0px;
}

#chapterListItem #space {
  width: 32px;
  /* height: 32px; */
  /* margin-right: 10px; */
}

#chapterListItem::part(native):hover {
  cursor: pointer;
}
