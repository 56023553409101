#answerBoxContainer {
  padding: 12px;
  font-family: noto-reg;
  font-size: 17px;
  border-radius: 4px;
  cursor: pointer;
  border-style: solid;
  margin-bottom: 12px;
  user-select: none;
  border-width: 1px;
  background-color: var(--answer-box-default-bg);
  color: var(--answer-box-default-color);
  /* border-color: white; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

#answerText {
  flex: 1;
}

#answerIcon {
  min-width: 24px;
  min-height: 24px;
  padding-left: 8px;
}

#multiAnswerIcon {
  min-width: 34px;
  min-height: 34px;
  filter: var(--answer-box-checkbox-icon);
}

/* ****************** */

.selected {
  border-width: 2px !important;
  border-color: var(--answer-box-selected-border);
}

.correct.answered,
.correct.unselected.answered {
  background-color: var(--answer-box-green-bg) !important;
  /* border-color: var(--answer-box-green-border); */
  color: var(--answer-box-green-text) !important;
}

.incorrect.selected.answered {
  background-color: var(--answer-box-red-bg) !important;
  /* border-color: var(--answer-box-red-border); */
  color: var(--answer-box-red-text) !important;
}

.answered.unselected {
  border-color: var(--answer-box-answered-unselected-border-color);
}

.unanswered.unselected {
  border-color: var(--answer-box-unanswered-unselected-border-color);
}
