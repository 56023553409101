/* layout */

.flex {
  display: flex;
}

.jc-center {
  justify-content: center;
}

.ai-center {
  align-items: center;
}

.flex-d-row {
  flex-direction: row;
}

.flex-d-col {
  flex-direction: column;
}

.flex-one {
  flex: 1;
}

/* colors */

.c-white {
  color: white;
}

.c-prim-50 {
  color: #00535d;
}

.c-prim-btn {
  color: #006b8c;
  /* --background: #006b8c; */
}

.c-red {
  color: red;
}

/* text align */
.ta-center {
  text-align: center;
}

/* background colors */

.bgc-prim-btn {
  background-color: #006b8c;
  --background: #006b8c;
}

/* borders */

.bt {
  border-top: 1px #b3b2b2 solid;
}

.bl {
  border-left: 0.5px #b3b2b2 solid;
}

.br {
  border-right: 0.5px #b3b2b2 solid;
}

.br-2 {
  --border-radius: 2px;
  border-radius: 2px;
}

.br-3 {
  --border-radius: 3px;
  border-radius: 3px;
}

.br-4 {
  --border-radius: 4px;
  border-radius: 4px;
}

.br-5 {
  --border-radius: 5px;
  border-radius: 5px;
}

.br-6 {
  --border-radius: 6px;
  border-radius: 6px;
}

.br-7 {
  --border-radius: 7px;
  border-radius: 7px;
}

.br-8 {
  --border-radius: 8px;
  border-radius: 8px;
}

/* font families */

.n-reg {
  font-family: noto-reg;
}

.n-bold {
  font-family: noto-bold;
}

.o-med {
  font-family: orbi-med;
}

.o-bold {
  font-family: orbi-bold;
}

/* font sizes */

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

/* margins */

.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px;
}

.m-12 {
  margin: 12px;
}

.m-16 {
  margin: 16px;
}

.m-20 {
  margin: 20px;
}

.m-24 {
  margin: 24px;
}

/* margin top */

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-22 {
  margin-top: 22px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

/* margin bottom */

.mb-4 {
  margin-bottom: 4px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-22 {
  margin-bottom: 22px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-26 {
  margin-bottom: 26px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-32 {
  margin-bottom: 32px;
}

/* background-activated */

.ba-none {
  --background-activated: none;
}

/* margin left */

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-22 {
  margin-left: 22px;
}

.ml-24 {
  margin-left: 24px;
}

/* margin right */

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-14 {
  margin-right: 14px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-18 {
  margin-right: 18px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-22 {
  margin-right: 22px;
}

.mr-24 {
  margin-right: 24px;
}

/* paddings */

.p-4 {
  padding: 4px;
}

.p-6 {
  padding: 6px;
}

.p-8 {
  padding: 8px;
}

.p-10 {
  padding: 10px;
}

.p-12 {
  padding: 12px;
}

.p-14 {
  padding: 14px;
}

.p-16 {
  padding: 16px;
}

.p-18 {
  padding: 18px;
}

.p-20 {
  padding: 20px;
}

.p-22 {
  padding: 22px;
}

.p-24 {
  padding: 24px;
}

/* padding top */

.pt-4 {
  padding-top: 4px;
}

.pt-6 {
  padding-top: 6px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-6 {
  padding-top: 6px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-14 {
  padding-top: 14px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-18 {
  padding-top: 18px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-22 {
  padding-top: 22px;
}

.pt-24 {
  padding-top: 24px;
}

/* padding bottom */

.pb-4 {
  padding-bottom: 4px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-4 {
  padding-bottom: 14px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-18 {
  padding-bottom: 18px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-22 {
  padding-bottom: 22px;
}

.pb-24 {
  padding-bottom: 24px;
}

/* padding left */

.pl-4 {
  padding-left: 4px;
}

.pl-6 {
  padding-left: 6px;
}

.pl-8 {
  padding-left: 8px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-12 {
  padding-left: 12px;
}

.pl-14 {
  padding-left: 14px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-18 {
  padding-left: 18px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-22 {
  padding-left: 22px;
}

.pl-24 {
  padding-left: 24px;
}

/* padding right */

.pr-4 {
  padding-right: 4px;
}

.pr-6 {
  padding-right: 6px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-12 {
  padding-right: 12px;
}

.pr-14 {
  padding-right: 14px;
}

.pr-16 {
  padding-right: 16px;
}

.pr-18 {
  padding-right: 18px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-22 {
  padding-right: 22px;
}

.pr-24 {
  padding-right: 24px;
}
