.overviewButton {
  border-radius: 2px;
  font-family: noto-bold;
  font-size: 17px;
  /* padding-top: 13px; */
  /* padding-bottom: 13px; */
  margin-top: 10px;
  margin-bottom: 10px;
  height: 43px;
}

.outline {
  background: none;
  border: solid 1px var(--overview-button-outline);
  color: var(--overview-button-outline);
}

.outline:active {
  background-color: var(--overview-button-outline-active);
  color: var(--overview-button-outline-active-text-color);
}

.fill {
  /* border-width: 0; */
  background-color: var(--overview-button-fill-bg);
  color: var(--overview-button-fill-text-color);
}

.fill:active {
  background-color: var(--overview-button-fill-bg-active);
}

.overviewBtnSpinner::part(native) {
  /* height: 12px; */
}
