#resetPasswordContent {
  --background: white;
}

.resetPassToolbar {
  --background: #00535d;
}

#resetPassInfo {
  margin-top: 16px;
  font-family: noto-reg;
  font-size: 16px;
  color: #000;
}

#resetContentContainer {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
