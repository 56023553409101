#bulletinListItem {
  --inner-padding-top: 20px;
  --inner-padding-bottom: 20px;
  --inner-padding-start: 20px;
  --inner-padding-end: 20px;
}

#bulletinListItem::part(detail-icon) {
  height: 100%;
}

#bulletinListItem::part(native) {
  padding-left: 0;
  padding-right: 0;
}

#bulletinListItem h2 {
  font-family: orbi-bold;
  font-size: 13px;
  color: var(--bulletin-date);
}

#bulletinListItem h1 {
  font-family: noto-bold;
  font-size: 20px;
  color: var(--bulletin-title);
  margin-top: 4px;
}
