/* noto sans fonts */

@font-face {
  font-family: noto-black;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-Black.ttf");
}

@font-face {
  font-family: noto-black-italic;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-BlackItalic.ttf");
}

@font-face {
  font-family: noto-bold;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-Bold.ttf");
}

@font-face {
  font-family: noto-bold-italic;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-BoldItalic.ttf");
}

@font-face {
  font-family: noto-extra-bold;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-ExtraBold.ttf");
}

@font-face {
  font-family: noto-extra-light;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-ExtraLight.ttf");
}

@font-face {
  font-family: noto-extra-light-italic;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-ExtraLightItalic.ttf");
}

@font-face {
  font-family: noto-italic;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-Italic.ttf");
}

@font-face {
  font-family: noto-light;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-Light.ttf");
}

@font-face {
  font-family: noto-light-italic;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-LightItalic.ttf");
}

@font-face {
  font-family: noto-med;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-Medium.ttf");
}

@font-face {
  font-family: noto-med-italic;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-MediumItalic.ttf");
}

@font-face {
  font-family: noto-reg;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-Regular.ttf");
}

@font-face {
  font-family: noto-semi-bold;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-SemiBold.ttf");
}

@font-face {
  font-family: noto-semi-bold-italic;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-SemiBoldItalic.ttf");
}

@font-face {
  font-family: noto-thin;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-Thin.ttf");
}

@font-face {
  font-family: noto-thin-italic;
  src: url("../../public/assets/fonts/noto-sans/NotoSans-ThinItalic.ttf");
}

/* orbitron fonts */

@font-face {
  font-family: orbi-black;
  src: url("../../public/assets/fonts/orbitron/Orbitron-Black.ttf");
}

@font-face {
  font-family: orbi-bold;
  src: url("../../public/assets/fonts/orbitron/Orbitron-Bold.ttf");
}

@font-face {
  font-family: orbi-extra-bold;
  src: url("../../public/assets/fonts/orbitron/Orbitron-ExtraBold.ttf");
}

@font-face {
  font-family: orbi-med;
  src: url("../../public/assets/fonts/orbitron/Orbitron-Medium.ttf");
}

@font-face {
  font-family: orbi-reg;
  src: url("../../public/assets/fonts/orbitron/Orbitron-Regular.ttf");
}

@font-face {
  font-family: orbi-semi-bold;
  src: url("../../public/assets/fonts/orbitron/Orbitron-SemiBold.ttf");
}

/* sf pro rounded for 'Aa' icon */
@font-face {
  font-family: SFProRounded-Medium;
  src: url("../../public/assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Medium.ttf");
}
