#quizResultsContainer {
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  min-height: 100%;
}

#quizResultsContainer h2 {
  font-family: orbi-bold;
  font-size: 15px;
  margin-top: 24px;
  color: var(--quiz-overview-mod-number);
}

#quizResultsContainer h1 {
  font-family: noto-bold;
  font-size: 22px;
  margin-top: 4px;
  color: var(--prev-results-title);
}

#quizResultsContainer h3 {
  font-family: noto-bold;
  font-size: 17px;
  margin-top: 16px;
  margin-bottom: 15px;
  color: var(--primary-accent);
}
