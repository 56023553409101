h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track-colour);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-colour);
  border-radius: 5px;
  /* background: red; */

  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-colour);
}

ion-tab-bar {
  --background: var(--tab-bar-bg);
  --border: solid 1px var(--tab-bar-border);
}

ion-tab-button ion-icon {
  width: 48px;
  height: 32px;
}

ion-tab-button ion-label {
  font-family: noto-reg;
  color: var(--tab-label-unselected);
}

ion-tab-button.tab-selected ion-label {
  font-family: noto-bold;
  color: var(--tab-label-selected);
}

/* dashboard tab */
ion-tab-button.tab-selected:nth-child(1) ion-icon {
  content: url("../../public/assets/svgs/Tab_Dashboard_Active.svg");
}

/* modules tab */
ion-tab-button.tab-selected:nth-child(2) ion-icon {
  content: url("../../public/assets/svgs/Tab_Modules_Active.svg");
}

/* quizzes tab */
ion-tab-button.tab-selected:nth-child(3) ion-icon {
  content: url("../../public/assets/svgs/Tab_Quiz_Active.svg");
}

/* bulletins tab */
ion-tab-button.tab-selected:nth-child(4) ion-icon {
  content: url("../../public/assets/svgs/Tab_Bulletins_Active.svg");
}

ion-tab-button ion-icon {
  filter: var(--tab-icon-default);
}

ion-tab-button.tab-selected ion-icon {
  filter: var(--tab-icon-selected);
}

/* colour the highlight if it's selected */
ion-tab-button.tab-selected #tabHighlight {
  background-color: var(--tab-highlight-coloured);
}

ion-toolbar {
  --color: white;
}

.roadHaulageBg {
  --background: var(--book-bg-rh);
}

.passengerTransportBg {
  --background: var(--book-bg-pt);
}

.roadHaulageBgc {
  /* background-color: white; */
  background-color: var(--book-bg-rh);
}

.passengerTransportBgc {
  background-color: var(--book-bg-pt);
}

ion-content {
  --background: var(--content-bg);
}

ion-item {
  --background: var(--item-bg);
}

::part(detail-icon) {
  --detail-icon-color: var(--detail-icon);
  --detail-icon-opacity: 1;
}

ion-title {
  font-family: orbi-semi-bold;
}

ion-back-button {
  color: white;
}

/* iPads or bigger */
@media only screen and (min-width: 600px) {
  #quizzesTabContentContainer,
  #dashboardContentContainer,
  #chapterListContainer,
  #quizOverviewContentContainer,
  #bulletinContentContainer,
  #moduleListContainer,
  #bulletinsListContainer,
  #quizResultsContainer,
  #quizResultsAnswersContainer,
  #loginContentContainer,
  #registerContentContainer,
  #iapContentContainer,
  #resetContentContainer {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  #moduleListContainer,
  #bulletinsListContainer {
    position: relative;
    left: 20px;
    right: 20px;
  }

  .swiperInnerWrapper {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

.contentWrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  background-color: var(--content-bg);
}

#quizzesTabContentContainer,
#dashboardContentContainer,
#chapterListContainer,
#quizOverviewContentContainer,
#bulletinContentContainer,
#quizResultsContainer,
#loginContentContainer,
#registerContentContainer,
#quizResultsAnswersContainer {
  background-color: var(--content-bg);
  padding-left: 20px;
  padding-right: 20px;
}

.basicDivider {
  min-height: 1px;
  background-color: var(--basic-divider);
}

/* icons in end of toolbar */
ion-toolbar ion-buttons ion-button.button-has-icon-only ion-icon {
  width: 44px;
  height: 44px;
}

/* buttons in end of toolbar */
ion-toolbar ion-buttons ion-button.button-has-icon-only::part(native) {
  padding: 0;
  margin: 0;
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(286deg) brightness(106%) contrast(101%);
}

.textControlsBtn {
  font-size: 22px !important;
  --color: white;
}

/* score colours */

.defaultScoreColour {
  color: var(--quiz-overview-box-best-attempt-score-zero);
}

.inProgressScoreColour {
  color: var(--quiz-overview-box-best-attempt-score-neutral);
}

.passScoreColour {
  color: var(--quiz-overview-box-best-attempt-score-pass);
}
