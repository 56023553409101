:root {
  --tab-bar-bg: #f7f7f7;
  --tab-bar-border: #b3b8bd;
  --tab-icon-default: "invert(78%) sepia(9%) saturate(76%) hue-rotate(131deg) brightness(86%) contrast(82%)";
  --tab-icon-selected: "invert(24%) sepia(17%) saturate(3393%) hue-rotate(146deg) brightness(99%) contrast(103%)";
  --tab-highlight-default: #f7f7f7;
  --tab-highlight-coloured: #fa4510;
  --tab-label-unselected: #a2a6a6;
  --tab-label-selected: #00535d;
  --book-bg-rh: #006b8c;
  --book-bg-pt: #8c0d1f;
  --content-bg: #ffffff;
  --item-bg: #ffffff;
  --module-list-item-number: #80a9af;
  --module-list-item-name: #00535d;
  --chapter-list-item: #000000;
  --detail-icon: #00535d;
  --status-not-started: #a9acb1;
  --status-in-progress: #b38114;
  --status-finished: #2ba052;
  --chapter-list-book-icon: invert(48%) sepia(60%) saturate(6216%) hue-rotate(352deg) brightness(96%) contrast(105%);
  --chapter-list-tick-icon: invert(73%) sepia(29%) saturate(306%) hue-rotate(140deg) brightness(85%) contrast(82%);
  --chapter-content-divider: #c9c9c9;
  --chapter-content-text: #000000;
  --reg-button: #00535d;
  --reg-button-hover: #077885;
  --reg-button-activated: #12808d;
  --module-buttons-label: #000000;
  --book-selection-divider: #c9c9c9;
  --swiper-controls-text: #00535d;
  --caret-icon: invert(17%) sepia(78%) saturate(2334%) hue-rotate(166deg) brightness(94%) contrast(102%);
  --highlight-default: yellow;
  --highlight-active: red;
  --search-input-bg: white;
  --occurences-container: #6f757d;
  --search-input-color: black;
  --dash-search-input-border: #c9c9c9;
  --dash-search-input-bg: #eeeeee;
  --dash-search-input-color: #6e7c7d;
  --search-input-icon: "invert(22%) sepia(27%) saturate(3564%) hue-rotate(156deg) brightness(96%) contrast(101%)";
  --cr-bubble-border: #c9c9c9;
  --cr-bubble-module-num: #80a9af;
  --cr-bubble-module-name: #00535d;
  --cr-bubble-chapter: #00535d;
  --cr-bubble-bg: white;
  --cr-bubble-bg-hover: rgba(0, 0, 0, 0.05);
  --cr-bubble-bg-active: rgba(0, 0, 0, 0.1);
  --cr-chapter-icon: "invert(16%) sepia(85%) saturate(1895%) hue-rotate(164deg) brightness(103%) contrast(102%)";
  --lb-bubble-border: #c9c9c9;
  --lb-bubble-bg: white;
  --lb-bubble-bg-hover: rgba(0, 0, 0, 0.05);
  --lb-bubble-bg-active: rgba(0, 0, 0, 0.1);
  --lb-bubble-date: #80a9af;
  --lb-bubble-title: #00535d;
  --dash-empty-section: #6f757d;
  --dash-bookmarks-mod-title: #80a9af;
  --quiz-results-bubble-bg: #e6f3f5;
  --quiz-results-bubble-border: #00535d;
  --quiz-results-bubble-heading: #00535d;
  --quiz-results-bubble-bg-hover: rgba(230, 243, 245, 0.65);
  --quiz-results-bubble-bg-active: rgba(230, 243, 245, 1);
  --quiz-bubble-border-width: 1px;
  --quiz-bubble-border-color-not-started: #c9c9c9;
  --quiz-bubble-border-color-in-progress: #4a360f;
  --quiz-bubble-border-color-passed: #117a2f;
  --see-all-button-bg: #e9ecf0;
  --see-all-button-color: #00535d;
  --quiz-bubble-status-icon: invert(35%) sepia(23%) saturate(1457%) hue-rotate(85deg) brightness(100%) contrast(99%);
  --quiz-bubble-bg-not-started: #ffffff;
  --quiz-bubble-bg-not-started-hover: rgba(0, 0, 0, 0.05);
  --quiz-bubble-bg-in-progress: #fcf5ca;
  --quiz-bubble-bg-in-progress-hover: rgb(251, 241, 178);
  --quiz-bubble-bg-passed: #d7f9cf;
  --quiz-bubble-bg-passed-hover: rgb(197, 245, 186);
  --quiz-bubble-module-num-not-started: #80a9af;
  --quiz-bubble-module-num-in-progress: #523600;
  --quiz-bubble-module-num-passed: #044429;
  --quiz-bubble-module-name-not-started: #000000;
  --quiz-bubble-module-name-in-progress: #4a360f;
  --quiz-bubble-module-name-passed: #044429;
  --quiz-bubble-status-not-started: #6f757d;
  --quiz-bubble-status-in-progress: #795300;
  --quiz-bubble-status-passed: #117a2f;
  --quiz-bubble-arrow-not-started: "invert(24%) sepia(57%) saturate(861%) hue-rotate(141deg) brightness(96%) contrast(101%)";
  --quiz-bubble-arrow-in-progress: "invert(19%) sepia(14%) saturate(2838%) hue-rotate(3deg) brightness(90%) contrast(88%)";
  --quiz-bubble-arrow-passed: "invert(18%) sepia(32%) saturate(1857%) hue-rotate(115deg) brightness(92%) contrast(97%)";
  --quiz-overview-mod-name: #000000;
  --quiz-overview-box-best-attempt-score: black;
  --box-item-divider: #c9c9c9;
  --overview-description: black;
  --overview-learn-more-link: #005be6;
  --overview-button-fill-bg: #00535d;
  --overview-button-fill-text-color: white;
  --overview-button-fill-bg-active: #058796;
  --overview-button-outline: #00535d;
  --overview-button-outline-active: #00535d;
  --overview-button-outline-active-text-color: white;
  --question-text: black;
  --question-mod-name: black;
  --question-mod-number: black;
  --answer-box-default-color: black;
  --answer-box-default-bg: #eff4f5;
  --answer-box-green-bg: #dcf9d1;
  --answer-box-red-bg: #f9ddd4;
  --answer-box-unanswered-unselected-border-color: #5c91a0;
  --answer-box-answered-unselected-border-color: #eff4f5;
  --answer-box-green-text: #003618;
  --answer-box-red-text: #330016;
  --answer-box-selected-border: black;
  --answer-box-checkbox-icon: "invert(15%) sepia(83%) saturate(2810%) hue-rotate(170deg) brightness(93%) contrast(101%)";
  --quiz-overview-box-best-attempt-score-zero: #c9c9c9;
  --quiz-overview-box-best-attempt-score-neutral: #e7b114;
  --quiz-overview-box-best-attempt-score-pass: #2ba052;
  --primary-accent: black;
  --secondary-accent: #00535d;
  --prev-results-title: #00535d;
  --basic-divider: #c9c9c9;
  --result-item-bg: #f0efed;
  --attempt-answer-icon-correct: invert(48%) sepia(85%) saturate(417%) hue-rotate(87deg) brightness(90%) contrast(82%);
  --attempt-answer-icon-incorrect: invert(59%) sepia(36%) saturate(2184%) hue-rotate(321deg) brightness(107%)
    contrast(82%);
  --status-label-not-started: #6f757d;
  --status-label-in-progress: #b38114;
  --status-label-passed: #117a2f;
  --results-sub-count: #00535d;
  --results-sub-text: #44828a;
  --bulletin-date: #80a9af;
  --bulletin-title: #00535d;
  --bulletin-text: black;
  --menu-item-label: #00535d;
  --menu-item-subtext: black;
  --color-black-white: black;
  --color-black-white-subtle: black;
  --th-bg: #bcbdc0;
  --scrollbar-thumb-colour: rgb(130, 126, 126);
  --scrollbar-thumb-hover-colour: rgb(95, 92, 92);
  --scrollbar-track-colour: white; /* #e4e4e4; */

  /* the following variables are used in components that look theh same in both themes (light/dark) */
  --loading-bg: #00535d;
  --loading-accent: #ffffff;
  --modal-button: #00535d;
  --modal-divider: #abb0b4;
  --swiper-controls-height: 50px;
  --popover-divider: rgba(0, 0, 0, 0.2);
  --popover-font-size: #6f757d;
  --popover-font-size-button: #00535d;
  --search-container-height: 44px;
  --dashboard-heading: #fa4510;
  --quiz-overview-mod-number: #80a9af;
  --results-h3: #fa4510;
}

body.dark {
  --tab-bar-bg: #22262e;
  --tab-bar-border: #40464f;
  --tab-icon-default: invert(46%) sepia(7%) saturate(535%) hue-rotate(183deg) brightness(96%) contrast(91%);
  --tab-icon-selected: invert(62%) sepia(76%) saturate(295%) hue-rotate(138deg) brightness(88%) contrast(106%);
  --tab-highlight-default: #22262e;
  --tab-highlight-coloured: #ff7b52;
  --tab-label-unselected: #84888f;
  --tab-label-selected: #c8e5e9;
  --book-bg-rh: #004b64;
  --book-bg-pt: #600e1a;
  --content-bg: #0c0e12;
  --item-bg: #0c0e12;
  --module-list-item-number: #97c3c9;
  --module-list-item-name: #c8e5e9;
  --chapter-list-item: #ffffff;
  --detail-icon: #6f757d;
  --status-not-started: #c9c9c9;
  --status-in-progress: #f7d36b;
  --status-finished: #57bf79;
  --chapter-list-book-icon: invert(63%) sepia(34%) saturate(2965%) hue-rotate(325deg) brightness(101%) contrast(101%);
  --chapter-list-tick-icon: invert(71%) sepia(13%) saturate(668%) hue-rotate(140deg) brightness(90%) contrast(84%);
  --chapter-content-divider: #40464f;
  --chapter-content-text: #ffffff;
  --reg-button: #00535d;
  --reg-button-hover: #077885;
  --reg-button-activated: #12808d;
  --module-buttons-label: #ffffff;
  --book-selection-divider: #40464f;
  --swiper-controls-text: rgba(255, 255, 255, 0.94);
  --caret-icon: invert(100%) sepia(0%) saturate(7500%) hue-rotate(272deg) brightness(100%) contrast(102%);
  --highlight-default: yellow;
  --highlight-active: red;
  --search-input-bg: #345a5f;
  --occurences-container: white;
  --search-input-color: white;
  --dash-search-input-border: #40464f;
  --dash-search-input-bg: #22262e;
  --dash-search-input-color: #979ca3;
  --dash-search-input-icon: invert(68%) sepia(1%) saturate(1710%) hue-rotate(174deg) brightness(92%) contrast(85%);
  --cr-bubble-border: #40464f;
  --cr-bubble-module-num: #97c3c9;
  --cr-bubble-module-name: #c8e5e9;
  --cr-bubble-chapter: #c8e5e9;
  --cr-bubble-bg: rgba(8, 65, 71, 0.25);
  --cr-bubble-bg-hover: rgba(8, 65, 71, 0.5);
  --cr-bubble-bg-active: rgba(8, 65, 71, 1);
  --cr-chapter-icon: invert(57%) sepia(1%) saturate(2065%) hue-rotate(175deg) brightness(80%) contrast(101%);
  --lb-bubble-border: #40464f;
  --lb-bubble-bg: rgba(8, 65, 71, 0.25);
  --lb-bubble-bg-hover: rgba(8, 65, 71, 0.5);
  --lb-bubble-bg-active: rgba(8, 65, 71, 1);
  --lb-bubble-date: #97c3c9;
  --lb-bubble-title: #c8e5e9;
  --dash-empty-section: #97c3c9;
  --dash-bookmarks-mod-title: #97c3c9;
  --quiz-results-bubble-bg: #62b9c4;
  --quiz-results-bubble-border: #62b9c4;
  --quiz-results-bubble-heading: #000;
  --quiz-results-bubble-bg-hover: rgba(98, 185, 196, 0.9);
  --quiz-results-bubble-bg-active: rgba(98, 185, 196, 1);
  --quiz-bubble-border-width: 0px;
  --see-all-button-bg: #00535d;
  --see-all-button-color: #fff;
  --quiz-bubble-status-icon: invert(30%) sepia(16%) saturate(2917%) hue-rotate(115deg) brightness(95%) contrast(99%);
  --quiz-bubble-bg-not-started: #22262e;
  --quiz-bubble-bg-not-started-hover: rgba(68, 75, 89, 0.5);
  --quiz-bubble-bg-in-progress: #f7d36b;
  --quiz-bubble-bg-in-progress-hover: #e4f76b;
  --quiz-bubble-bg-passed: #57bf79;
  --quiz-bubble-bg-passed-hover: #2dc460;
  --quiz-bubble-module-num-not-started: #80a9af;
  --quiz-bubble-module-num-in-progress: #8c5911;
  --quiz-bubble-module-num-passed: #016744;
  --quiz-bubble-module-name-not-started: #ffffff;
  --quiz-bubble-module-name-in-progress: #000000;
  --quiz-bubble-module-name-passed: #000000;
  --quiz-bubble-status-not-started: #c9c9c9;
  --quiz-bubble-status-in-progress: #8c5911;
  --quiz-bubble-status-passed: #016744;
  --quiz-bubble-arrow-not-started: invert(100%) sepia(0%) saturate(0%) hue-rotate(98deg) brightness(108%) contrast(103%);
  --quiz-bubble-arrow-in-progress: invert(0%) sepia(4%) saturate(15%) hue-rotate(71deg) brightness(102%) contrast(104%);
  --quiz-bubble-arrow-passed: invert(0%) sepia(4%) saturate(15%) hue-rotate(71deg) brightness(102%) contrast(104%);
  --quiz-overview-box-title: #ffffff;
  --quiz-overview-box-best-attempt-score: white;
  --box-item-divider: #40464f;
  --overview-description: white;
  --overview-learn-more-link: #56c8d9;
  --overview-button-fill-bg: #28666d;
  --overview-button-fill-text-color: white;
  --overview-button-fill-bg-active: #36919b;
  --overview-button-outline: #81d5e1;
  --overview-button-outline-active: #81d5e1;
  --overview-button-outline-active-text-color: black;
  --question-text: white;
  --question-mod-name: white;
  --question-mod-number: white;
  --answer-box-default-color: white;
  --answer-box-default-bg: #22262e;
  --answer-box-green-bg: #57bf79;
  --answer-box-red-bg: #e87c6a;
  --answer-box-unanswered-unselected-border-color: #6f757d;
  --answer-box-answered-unselected-border-color: #22262e;
  /* --answer-box-green-border: #57bf79; */
  /* --answer-box-red-border: #e87c6a; */
  --answer-box-green-text: #000000;
  --answer-box-red-text: #000000;
  --answer-box-selected-border: white;
  --answer-box-checkbox-icon: invert(93%) sepia(93%) saturate(0%) hue-rotate(298deg) brightness(107%) contrast(106%);
  --quiz-overview-box-best-attempt-score-zero: #c9c9c9;
  --quiz-overview-box-best-attempt-score-neutral: #f7d36b;
  --quiz-overview-box-best-attempt-score-pass: #57bf79;
  --primary-accent: white;
  --prev-results-title: white;
  --basic-divider: #40464f;
  --result-item-bg: rgba(240, 239, 237, 0.1);
  --secondary-accent: white;
  --attempt-answer-icon-correct: invert(48%) sepia(85%) saturate(417%) hue-rotate(87deg) brightness(90%) contrast(82%);
  --attempt-answer-icon-incorrect: invert(59%) sepia(36%) saturate(2184%) hue-rotate(321deg) brightness(107%)
    contrast(82%);
  --status-label-not-started: #6f757d;
  --status-label-in-progress: #f7d36b;
  --status-label-passed: #117a2f;
  --results-sub-count: black;
  --results-sub-text: #03515b;
  --bulletin-date: #97c3c9;
  --bulletin-title: #bcd7dc;
  --bulletin-text: white;
  --menu-item-label: #bcd7dc;
  --menu-item-subtext: white;
  --color-black-white: white;
  --color-black-white-subtle: #bcd7dc;
  --th-bg: none;
  --scrollbar-thumb-colour: #888;
  --scrollbar-thumb-hover-colour: rgb(115, 114, 114);
  --scrollbar-track-colour: rgb(207, 206, 206);
}
