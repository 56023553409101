.bookmarksModal {
  /* background: red; */
}

.bookmarksModal::part(content) {
  --width: 100%;
  --height: 90vh;
  margin-bottom: -10vh;
  --border-radius: 10px 10px 0 0;
  background-color: var(--content-bg);
}

@media only screen and (min-width: 600px) {
  .bookmarksModal::part(content) {
    --width: 75%;
  }
}

#bookmarksModalToolbar {
  padding-top: 0;
}
