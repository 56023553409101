#bulletinContentContainer div:nth-child(1) {
  margin-top: 24px;
  font-family: orbi-bold;
  font-size: 13px;
  color: var(--bulletin-date);
}

#bulletinContentContainer div:nth-child(2) {
  margin-top: 8px;
  color: var(--bulletin-title);
  font-family: noto-bold;
  font-size: 20px;
}

#bulletinContentContainer div:nth-child(3) {
  margin-top: 24px;
  font-family: noto-reg;
  font-size: 17px;
  color: var(--bulletin-text);
}
