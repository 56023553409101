ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

#bookSelectionDivider {
  height: 1px;
  background-color: var(--book-selection-divider);
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

#rhaLogo {
  width: 83px;
}

#bookSelectionContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

#bookSelectionContainer ion-select::part(text) {
  font-family: orbi-semi-bold;
  color: white;
}

#bookSelectionContainer ion-select {
  --padding-end: 5x;
  --padding-start: 10px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

#bookSelectionContainer ion-select::part(icon) {
  color: white;
  content: url("../../../../public/assets/svgs/Button_Disclosure_Down.svg");
  filter: invert(99%) sepia(0%) saturate(7500%) hue-rotate(263deg) brightness(105%) contrast(103%);
  opacity: 1;
  width: 44px;
  height: 44px;
}

#bookSelectionContainer ion-select-option {
  --color: white;
  color: white;
  background-color: blue !important;
}

.sc-ion-select-popover-md {
  padding: 0;
  font-family: orbi-semi-bold !important;
  --color: white;
  --background-hover: none !important;
  --background-hover-opacity: 1 !important;
}

#bookSelectionContainer button {
  --background-hover: none;
}

.sc-ion-select-popover-md ion-item:nth-child(1) {
  --background: var(--book-bg-rh);
}

.sc-ion-select-popover-md ion-item:nth-child(2) {
  --background: var(--book-bg-pt);
}

.item-radio-checked.sc-ion-select-popover-md {
  --background-focused: none;
  --background-focused-opacity: 1;
}

#dashboardContentContainer h1 {
  user-select: none;
  margin-top: 32px;
  margin-bottom: 12px;
  font-family: orbi-med;
  font-size: 20px;
  color: var(--dashboard-heading);
}

#bookmarksHeadingContainer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  height: 28px;
}

#bookmarksHeadingContainer h1 {
  margin-top: 0;
}

#bookmarksHeadingContainer ion-button::part(native) {
  --background: var(--see-all-button-bg);
  --background-hover: var(--see-all-button-bg);
  --background-activated: var(--see-all-button-bg);
  color: var(--see-all-button-color);
  border-radius: 14px;
  height: 28px;
  font-family: noto-bold;
}

#bookmarksHeadingContainer ion-button {
  /* --margin: 0; */
  /* --padding: 0; */
}

#menuButton {
  --color: white;
}

#menuContent {
  /* --background: white; */
  --background: var(--content-bg);
}

.menuItemSlide {
  /* border: solid 1px black; */
  /* height: 100%; */
}

#menuSwiper {
  /* overflow-y: scroll; */
  height: 100%;
}

.dashSubMenu {
  height: 100%;
  /* background-color: green; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: var(--content-bg);
  overflow-y: scroll;
}

.searchbar-input-container {
  height: 100% !important;
  /* border-radius: 6px !important; */
}

#searchInputItem {
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 32px;
  --background: var(--dash-search-input-bg);
  border-radius: 10px;
  border: solid 1px var(--dash-search-input-border);
  color: var(--dash-search-input-color);
}

.searchInputContainer {
  background: var(--dash-search-input-bg);
}

.searchResultItem {
  color: var(--primary-accent) !important;
  cursor: pointer;
}

.searchResultItem:active {
  background: var(--cr-bubble-bg-active);
}

.searchResultItem:hover {
  background: var(--cr-bubble-bg-hover);
}

.searchResultItem div:nth-child(1) {
  /* color: var(--module-list-item-name); */
  font-family: orbi-bold;
  font-size: 13px;
  color: var(--module-list-item-number);
  margin-top: 8px;
}

.searchResultItem div:nth-child(2) {
  color: var(--module-list-item-name);
  font-family: noto-bold;
  font-size: 18px;
  margin-top: 6px;
}

.searchResultItem div:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchResultItem div:nth-child(3) p {
  margin-top: 6px;
  margin-bottom: 8px;
  font-family: noto-reg;
  font-size: 14px;
  padding: 0;
}

.searchTextHighlight {
  color: black;
  background-color: var(--highlight-active);
}

#searchInputItem ion-input {
  font-family: noto-reg;
}

#searchViewContainer {
  height: 100%;
}

ion-searchbar button {
  background: var(--dash-search-input-bg) !important;
  margin-left: -10px !important;
  padding-right: 10px !important;
  z-index: 100;
}

#searchInputItem ion-icon {
  filter: var(--dash-search-input-icon);
  margin-right: 10px;
}

#searchInputItem::part(native) {
  padding-left: 10px;
}

#menuBack {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(19deg) brightness(100%) contrast(101%);
  margin-right: -15px;
  margin-left: -20px;
  height: 55px;
}

.dashRegText {
  font-family: noto-reg;
  font-size: 17px;
  margin-top: 4px;
  color: var(--color-black-white-subtle);
  margin-top: 12px;
  margin-bottom: 12px;
}

.dashHeaderText {
  font-family: orbi-bold;
  font-size: 22px;
  color: #00535d;
}
