#previousResultItemContainer {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding-left: 4px;
}

#previousResultItemContainer:hover {
  background-color: var(--result-item-bg);
}

#previousResultItemContainer div {
  flex: 1;
}

#previousResultItemIcon {
  width: 44px;
  height: 44px;
  filter: var(--caret-icon);
}

#previousResultItemContainer #dateTime {
  color: var(--primary-accent);
  font-family: noto-reg;
  font-size: 15px;
  margin-bottom: 8px;
}

#previousResultItemContainer #scoreLabel {
  font-family: noto-reg;
  font-size: 17px;
  color: var(--primary-accent);
}

#previousResultItemContainer #totalScore {
  font-family: noto-bold;
  font-size: 34px;
}
