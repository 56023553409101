#textControlsPopover::part(content) {
  /* width: 450px; */
  width: auto;
  /* --height: auto; */
  /* padding: 12px; */
  right: 5px;
  left: auto;
}

#textControlsPopover ion-content {
  --background: white;
}

#textControlsPopover ion-content div:nth-child(1) {
  padding: 10px;
  /* background-color: white; */
  /* margin: 10px; */
  /* width: auto; */
  /* padding: 10px; */
}

#textControlsPopover ion-content div:nth-child(2) {
  height: 1px;
  background-color: var(--popover-divider);
  margin-left: 10px;
  margin-right: 10px;
}

#textControlsPopover ion-content div:nth-child(3) {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  /* background-color: white; */

  /* padding: 12px; */
  /* margin: 10px; */
  /* width: auto; */
  /* padding: 10px; */
}

#textControlsPopover ion-button {
  flex: 1;
}

#textSizePercentage {
  padding-left: 22px;
  padding-right: 22px;
  color: var(--popover-font-size);
}

#textSizeDecrease {
  border-right: solid 1px var(--popover-divider);
  color: var(--popover-font-size-button);
}

#textSizeIncrease {
  border-left: solid 1px var(--popover-divider);
  color: var(--popover-font-size-button);
}

ion-segment-button {
  padding-left: 10px;
  padding-right: 10px;
}
