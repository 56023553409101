#quizResultsBubble {
  margin-top: 24px;
  margin-bottom: 24px;
  border: solid 1px var(--quiz-results-bubble-border);
  border-radius: 10px 0 10px 0;
  padding: 24px 12px;
  /* cursor: pointer; */
  background-color: var(--quiz-results-bubble-bg);
}

/* #quizResultsBubble:hover {
  background-color: var(--quiz-results-bubble-bg-hover);
} */

#quizResultsBubble:active {
  background-color: var(--quiz-results-bubble-bg-active);
}

#quizResultsBubble h1 {
  font-family: noto-bold;
  font-size: 28px;
  color: var(--quiz-results-bubble-heading);
  user-select: none;
}

#resultsSubContainer {
  /* flex: 1; */
  margin-top: 16px;
}

#resultsSubCount {
  margin-left: 4px;
  margin-top: 4px;
  font-family: noto-bold;
  font-size: 22px;
  color: var(--results-sub-count);
}

#resultsSubText {
  flex: 1;
  font-family: noto-bold;
  font-size: 15px;
  color: var(--results-sub-text);
}

#resultsSubImg {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
