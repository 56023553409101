.confirmationModal {
  /* bottom: 0; */
  /* min-height: initial; */
  /* top: initial; */
}

.confirmationModal::part(content) {
  --width: 250px;
  /* --height: 100px; */
  --height: auto;
  --border-radius: 10px;
  background-color: white;
  border: 1px #b3b2b2 solid;
}

@media only screen and (min-width: 600px) {
  .confirmationModal::part(content) {
    --width: 350px;
  }
}
