#loadingPageContainer {
  /* --offset-top: env(safe-area-inset-top); */
  /* --padding-top: env(safe-area-inset-top); */
  --background: var(--loading-bg);
}

.contentsModal ion-toolbar:first-of-type {
  padding-top: 0px;
}

#loadingPageContainer div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--loading-accent);
  font-family: orbi-med;
}

#loadingPageContainer ion-spinner {
  --color: var(--loading-accent);
  margin-bottom: 12px;
}
