#quizBubble {
  /* border: solid 1px var(--quiz-bubble-bg-border); */
  border-radius: 10px 0 10px 0;
  border-style: solid;
  border-width: var(--quiz-bubble-border-width);
  padding: 16px 12px 8px 12px;
  cursor: pointer;
  margin-bottom: 12px;
  position: relative;
}

#quizBubble.notStarted {
  border-color: var(--quiz-bubble-border-color-not-started);
  background-color: var(--quiz-bubble-bg-not-started);
}

#quizBubble.notStarted:active {
  background-color: var(--quiz-bubble-bg-not-started-hover);
}

#quizBubble.inProgress {
  border-color: var(--quiz-bubble-border-color-in-progress);
  background-color: var(--quiz-bubble-bg-in-progress);
}

#quizBubble.inProgress:active {
  background-color: var(--quiz-bubble-bg-in-progress-hover);
}

#quizBubble.passed {
  border-color: var(--quiz-bubble-border-color-passed);
  background-color: var(--quiz-bubble-bg-passed);
}

#quizBubble.passed:active {
  background-color: var(--quiz-bubble-bg-passed-hover);
}

/* If the browser supports proper/true/real/non-emulated hovering 
(e.g. has a mouse-like primary input device), 
then apply this style when items are hovered over." */
@media (hover: hover) {
  #quizBubble.notStarted:hover {
    background-color: var(--quiz-bubble-bg-not-started-hover);
  }
  #quizBubble.inProgress:hover {
    background-color: var(--quiz-bubble-bg-in-progress-hover);
  }
  #quizBubble.passed:hover {
    background-color: var(--quiz-bubble-bg-passed-hover);
  }
}

#quizBubbleStatus {
  font-family: noto-bold;
  font-size: 15px;
  user-select: none;
}

#quizBubbleStatus.notStarted {
  color: var(--quiz-bubble-status-not-started);
}

#quizBubbleStatus.inProgress {
  color: var(--quiz-bubble-status-in-progress);
}

#quizBubbleStatus.passed {
  color: var(--quiz-bubble-status-passed);
}

#quizBubble h3,
h1 {
  user-select: none;
}

#quizBubble h3 {
  font-family: orbi-bold;
  font-size: 15px;
}

#quizBubble h1 {
  font-family: noto-bold;
  font-size: 22px;
  margin-top: 4px;
}

#quizBubbleModuleNum.notStarted {
  color: var(--quiz-bubble-module-num-not-started);
}

#quizBubbleModuleNum.inProgress {
  color: var(--quiz-bubble-module-num-in-progress);
}

#quizBubbleModuleNum.passed {
  color: var(--quiz-bubble-module-num-passed);
}

#quizBubbleModuleName.notStarted {
  color: var(--quiz-bubble-module-name-not-started);
}

#quizBubbleModuleName.inProgress {
  color: var(--quiz-bubble-module-name-in-progress);
}

#quizBubbleModuleName.passed {
  color: var(--quiz-bubble-module-name-passed);
}

#quizBubbleStatusIcon {
  width: 24px;
  height: 24px;
  /* filter: var(--quiz-bubble-status-icon); */
}

#quizBubbleArrowIcon {
  width: 44px;
  height: 44px;
}

#quizBubbleArrowIcon.notStarted {
  filter: var(--quiz-bubble-arrow-not-started);
}

#quizBubbleArrowIcon.inProgress {
  filter: var(--quiz-bubble-arrow-in-progress);
}

#quizBubbleArrowIcon.passed {
  filter: var(--quiz-bubble-arrow-passed);
}

#statusRowContainer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#statusRowContainer div {
  display: flex;
  align-items: center;
}

#statusRowContainer span {
  /* margin-left: 5px; */
}

#quizBubbleBackgroundIcon {
  position: absolute;
  right: 25px;
  top: 2px;
  bottom: 2px;
  /* top: px; */
  height: 100%;
  /* width: 122px; */
  opacity: 0.1;
}

#quizBubbleBackgroundIcon.notStarted {
  /* content: url("../../../public/assets/images/Icon_In_Progress@3x.png"); */
  /* content: url("../../../public/assets/images/Icon_In_Progress_Dark@3x.png"); */
}

#quizBubbleBackgroundIcon.inProgress {
  content: url("../../../public/assets/images/Icon_In_Progress_Dark@3x.png");
}

#quizBubbleBackgroundIcon.passed {
  content: url("../../../public/assets/images/Icon_Completed_Green@3x.png");
}

#quizBubbleStatusIcon.notStarted {
  /* content: url("./assets/svgs/Button_Answer_Tick.svg"); */
}

#quizBubbleStatusIcon.inProgress {
  content: url("../../../public/assets/images/Icon_In_Progress_Dark@3x.png");
}

#quizBubbleStatusIcon.passed {
  content: url("../../../public/assets/images/Icon_Completed_Green@3x.png");
}
