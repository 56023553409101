#loginContent {
  --background: white;
}

#loginContent .contentWrapper {
  background: white !important;
}

#loginContentContainer {
  /* border: solid 1px black; */
  background: white !important;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#loginHeaderImgContainer {
  position: relative;
  min-height: 25%;
  height: 25%;
  /* min-height: 225px; */
  /* height: 225px; */
  background-size: cover !important;
}

#loginHeaderImg {
  object-fit: contain;
  width: 100%;
}

#loginHeaderImgOverlay {
  color: white;
  font-family: orbi-med;
  font-size: 22px;
  position: absolute;
  /* text-align: center; */
  bottom: 24px;
  right: 20px;
  left: 20px;
  /* border: solid 2px yellow; */
}

#passwordInputContainer {
  height: auto !important;
  display: flex;
  flex-direction: row;
  border: solid 1px #e1e3e6;
  border-radius: 6px;
  margin-top: 8px;
  background: #eee;
}

#passwordInputContainer ion-input {
  border-radius: 0;
  border: none;
  --padding-start: 10px;
  --padding-end: 0;
}

#passwordInputContainer ion-button {
  color: var(--cta-blue) !important;
  height: auto !important;
  font-family: noto-reg;
  font-size: 15px;
  color: #00535d;
}

@media only screen and (min-width: 600px) {
  #loginHeaderImgOverlay {
    right: calc(20% + 20px);
    left: calc(20% + 20px);
  }

  .loginBtn {
    width: 55% !important;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }
}

.inputLabel {
  font-family: noto-reg;
  font-size: 13px;
  color: #666;
  margin-top: 16px;
}

.loginTitle {
  font-family: orbi-med;
  font-size: 22px;
  color: #00535d;
  margin-top: 24px;
}

.userInput {
  border: solid 1px #e1e3e6;
  border-radius: 6px;
  margin-top: 8px;
  --padding-start: 10px;
  --padding-end: 10px;
  --background: #eee;
}

.loginBtn {
  margin-top: 32px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  --border-radius: 2px;
  --background: #00535d;
  --background-activated: #038594;
  --background-hover: none;
}

#loginErrorMsg {
  border: solid 2px #db2136;
  background-color: #fae9e3;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px 10px 4px;
  color: #a10a1b;
  font-family: noto-reg;
  font-size: 16px;
}

#loginErrorMsgIcon {
  filter: invert(17%) sepia(74%) saturate(3290%) hue-rotate(340deg) brightness(103%) contrast(96%);
  width: 44px !important;
  height: 44px !important;
  margin-right: 4px;
}

#passwordResetLink {
  margin-top: 16px;
  font-family: noto-reg;
  text-decoration: underline;
  text-align: center;
  /* cursor: pointer; */
}

#registerLink {
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: center;
  font-size: 16px;
  font-family: noto-reg;
  text-decoration: underline;
  color: #00535d;
}

#registerLink:hover {
  opacity: 1;
}

#termsAndConditionsLink {
  text-align: center;
  color: #00535d;
  font-family: noto-reg;
}

#termsAndConditionsLink a {
  cursor: pointer;
}

#eosLogo {
  margin-top: 12px;
  margin-bottom: 12px;
}
